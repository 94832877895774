


export const gradientText = {
    from: {
        default: 'from-primary-400 dark:from-primary-300',
    },
    to: {
        default: 'to-primary-600 dark:to-primary-500'
    }
}