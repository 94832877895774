
// TODO update menus and errorSlides
export const socialMenu = [
    {
      label: 'Facebook',
      url: 'https://www.facebook.com/onderwijsloket/',
      icon: 'i-bxl-facebook'
    },
    {
      label: 'Twitter',
      url: 'https://twitter.com/onderwijsloket',
      icon: 'i-bxl-twitter'
    },
    {
      label: 'LinkedIn',
      url: 'https://www.linkedin.com/company/onderwijsloket',
      icon: 'i-bxl-linkedin'
    },
    {
      label: 'Instagram',
      url: 'https://www.instagram.com/onderwijsloket/',
      icon: 'i-bxl-instagram'
    }
]

export const footerMenu = [
    {
      label: 'Meer weten over',
      children: [
        {
          id: '1',
          name: 'Werken in het basisonderwijs',
          to: "https://www.onderwijsloket.com/kennisbank/in-het-basisonderwijs",
          external: true,
        },
        {
          id: '2',
          name: 'Werken in het voortgezet onderwijs',
          to: "https://www.onderwijsloket.com/kennisbank/in-het-voortgezet-onderwijs",
          external: true,
        },
        {
          id: '3',
          name: 'Werken in het mbo',
          to: "https://www.onderwijsloket.com/kennisbank/in-het-middelbaar-beroepsonderwijs",
          external: true,
        },
        {
          id: '4',
          name: 'Werken in het speciaal onderwijs',
          to: "https://www.onderwijsloket.com/kennisbank/",
          external: true,
        },
        {
          id: '5',
          name: 'Werken in het hoger onderwijs',
          to: "https://www.onderwijsloket.com/kennisbank/",
          external: true,
        }
      ]
    },
    {
      label: 'Ga direct naar',
      children: [
        {
          id: '6',
          name: 'Persoonlijk adviesgesprek',
          to: "https://www.onderwijsloket.com/plan-een-adviesgesprek/",
          external: true,
        },
        {
          id: '7',
          name: 'Veelgestelde vragen',
          to: "https://www.onderwijsloket.com/kennisbank/faq-archief/",
          external: true,
        },
        {
          id: '8',
          name: 'Lerarenopleidingen',
          to: 'https://navigator.onderwijsloket.com/opleidingen/',
          external: true
        },
        {
          id: '9',
          name: 'Ervaringen van anderen',
          to: "https://www.onderwijsloket.com/kennisbank/ervaringen-uit-het-onderwijs/",
          external: true,
        },
        {
          id: '10',
          name: 'Overstappen uit het buitenland',
          to: "https://www.onderwijsloket.com/en/",
          external: true,
        }
      ]
    },
    {
      label: 'Handige tools',
      children: [
        {
          id: '11',
          name: 'Kennisbank',
          to: "https://www.onderwijsloket.com/kennisbank/",
          external: true,
        },
        {
          id: '12',
          name: 'Onderwijsnavigator',
          to: "https://navigator.onderwijsloket.com",
          external: true
        },
        {
          id: '13',
          name: 'Routetool',
          to: 'https://onderwijsloket.com/routes/start',
          external: true
        },
        {
          id: '14',
          name: 'Regioloketten zoeken',
          to: "https://navigator.onderwijsloket.com/regioloketten/",
          external: true
        },
      ]
    },
    {
      label: 'Over het loket',
      children: [
        {
          id: '15',
          name: 'Contact',
          to: "https://www.onderwijsloket.com/contact/",
          external: true,
        },
        {
          id: '16',
          name: 'Onze onderwijsexperts',
          to: "https://www.onderwijsloket.com/onze-onderwijsexperts/",
          external: true
        },
        {
          id: '17',
          name: 'Onze missie',
          to: 'https://www.onderwijsloket.com/over-het-loket/',
          external: true
        },
        {
          id: '18',
          name: 'Info voor organisaties',
          to: "https://stats.onderwijsloket.com",
          external: true
        },
        {
          id: '19',
          name: 'Over jouw privacy',
          to: 'https://onderwijs.in/privacy/',
          external: true
        }
      ]
    }
]
  
export const mainMenu: MenuItem[] = [
    {
        id: "27ed1df5-c658-4b20-8318-523de5ec0ee1",
        name: "Kennisbank",
        hideGroupName: false,
        type: "dropdown",
        icon: null,
        to: "https://onderwijsloket.com/kennisbank/",
        external: true,
        image: null,
        description: null,
        sort: 0,
        item: [],
        children: [
          {
            id: "4778d8a6-cb22-4753-813e-1b557f0032c0",
            name: "Kennisbank",
            hideGroupName: false,
            type: "link",
            icon: "menu_book",
            to: "https://onderwijsloket.com/kennisbank/",
            external: true,
            image: null,
            description: "Doorzoek alle informatie van het Onderwijsloket",
            sort: 0,
            item: [],
            children: [],
          },
          {
            id: "59e790af-f137-49b4-8796-8421d15fdff7",
            name: "Link Groups",
            hideGroupName: true,
            type: "groups",
            icon: null,
            to: null,
            image: null,
            description: null,
            sort: 1,
            item: [],
            children: [
              {
                id: "723c3dc0-815c-42af-acc8-45a6aa028cfe",
                name: "Meer weten over",
                hideGroupName: false,
                type: "group",
                icon: null,
                to: null,
                image: null,
                description: null,
                sort: 0,
                item: [],
                children: [
                  {
                    id: "4c08eb0b-75dc-41be-90bd-d2c9d030227b",
                    name: "Het primair onderwijs",
                    hideGroupName: false,
                    type: "link",
                    icon: null,
                    to: "https://www.onderwijsloket.com/kennisbank/in-het-basisonderwijs",
                    external: true,
                    image: null,
                    description: null,
                    sort: 0,
                    item: [],
                  },
                  {
                    id: "bbc6773a-baad-4249-b915-b05dbf6873ec",
                    name: "Het voortgezet onderwijs",
                    hideGroupName: false,
                    type: "link",
                    icon: null,
                    to: "https://www.onderwijsloket.com/kennisbank/in-het-voortgezet-onderwijs",
                    external: true,
                    image: null,
                    description: null,
                    sort: 1,
                    item: [],
                  },
                  {
                    id: "6bf54857-bc5c-4d52-9433-b2896b50a7ba",
                    name: "Het middelbaar beroepsonderwijs",
                    hideGroupName: false,
                    type: "link",
                    icon: null,
                    to: "https://www.onderwijsloket.com/kennisbank/in-het-middelbaar-beroepsonderwijs",
                    external: true,
                    image: null,
                    description: null,
                    sort: 2,
                    item: [],
                  },
                  {
                    id: "6a587b4f-c6f4-4ad0-9d4c-d7069d445320",
                    name: "Algemene zaken",
                    hideGroupName: false,
                    type: "link",
                    icon: null,
                    to: "https://www.onderwijsloket.com/kennisbank/praktische-zaken",
                    external: true,
                    image: null,
                    description: null,
                    sort: 3,
                    item: [],
                  },
                  {
                    id: "fadeb002-46c0-4793-8ec5-b98f2903a3ff",
                    name: "Overstappen vanuit het buitenland",
                    hideGroupName: false,
                    type: "link",
                    icon: null,
                    to: "https://www.onderwijsloket.com/en/",
                    external: true,
                    image: null,
                    description: null,
                    sort: 4,
                    item: [],
                  },
                ],
              },
              {
                id: "56a1cd6c-ff6b-4067-b5ef-992231201709",
                name: "Doorzoek onze",
                hideGroupName: false,
                type: "group",
                icon: null,
                to: null,
                image: null,
                description: null,
                sort: 1,
                item: [],
                children: [
                  {
                    id: "874d9d7d-83fc-4adb-9cd5-b652cf4f808c",
                    name: "Artikelen",
                    hideGroupName: false,
                    type: "link",
                    icon: "description",
                    to: "https://www.onderwijsloket.com/kennisbank/artikel-archief/",
                    external: true,
                    image: null,
                    description: null,
                    sort: 0,
                    item: [],
                  },
                  {
                    id: "f19ef0fe-ca0b-40f1-a247-02a87f8a6c8a",
                    name: "Veelgestelde vragen",
                    hideGroupName: false,
                    type: "link",
                    icon: "chat",
                    to: "https://www.onderwijsloket.com/kennisbank/faq-archief/",
                    external: true,
                    image: null,
                    description: null,
                    sort: 1,
                    item: [],
                  },
                  {
                    id: "7e8ea305-b4f1-4053-bf56-bc02cad53675",
                    name: "Gidsen",
                    hideGroupName: false,
                    type: "link",
                    icon: "send",
                    to: "https://voorbereiden.onderwijsloket.com/gidsen/",
                    external: true,
                    image: null,
                    description: null,
                    sort: 2,
                    item: [],
                  },
                ],
              },
            ],
          },
          {
            id: "4a41f943-2c90-43c3-8521-d346d30b8879",
            name: "Tool Section",
            hideGroupName: true,
            type: "section",
            icon: null,
            to: null,
            image: null,
            description: null,
            sort: 2,
            item: [],
            children: [
              {
                id: "0f819936-fe17-4520-89a0-33fdbf62b97c",
                name: "Routetool",
                hideGroupName: false,
                type: "link",
                icon: "alt_route",
                to: "https://www.onderwijsloket.com/routes/start",
                external: true,
                image: null,
                description: "Ontdek welke routes naar het onderwijs bij jou passen",
                sort: 0,
                item: [],
                children: [],
              },
              {
                id: "a9eca646-bbb4-4f31-90da-852e87a9fb8e",
                name: "Navigator",
                hideGroupName: false,
                type: "link",
                icon: "contact_emergency",
                to: "https://navigator.onderwijsloket.com",
                external: true,
                image: null,
                description:
                  "Zoek naar lerarenopleidingen, regioloketten en andere contacten",
                sort: 1,
                item: [],
                children: [],
              },
            ],
          },
        ],
    },
    {
        id: "2128df58-99dc-49a7-bea2-d496771606f8",
        name: "Media",
        hideGroupName: false,
        type: "dropdown",
        icon: null,
        to: null,
        image: null,
        description: null,
        sort: 1,
        item: [],
        children: [
          {
            id: "6f75f82d-7fd3-42e1-898c-32417a985291",
            name: "Media",
            hideGroupName: false,
            type: "link",
            icon: "perm_media",
            to: null,
            image: null,
            description: "Ontdek onze podcasts, video's en andere interviews",
            sort: 0,
            item: [],
            children: [],
          },
          {
            id: "4bd753cb-c2b3-493c-a8de-551b5dfa15c8",
            name: "Link groups",
            hideGroupName: false,
            type: "groups",
            icon: null,
            to: null,
            image: null,
            description: null,
            sort: 1,
            item: [],
            children: [
              {
                id: "d10d23ca-a81b-4af5-b923-e7de52054d91",
                name: "Media links",
                hideGroupName: true,
                type: "group",
                icon: null,
                to: null,
                image: null,
                description: null,
                sort: 0,
                item: [],
                children: [
                  {
                    id: "f719ab5c-9991-4fba-9233-5e025523a5ec",
                    name: "Podcasts",
                    hideGroupName: false,
                    type: "link",
                    icon: "mic",
                    to: "https://open.spotify.com/user/31sdtnxdz5stot6vtbw23lwy2xje?si=b8b8a4eba0ea4f49",
                    external: true,
                    image: null,
                    description: null,
                    sort: 0,
                    item: [],
                  },
                  {
                    id: "41e2d568-6d6a-4167-93d2-86206e5f0098",
                    name: "Video's",
                    hideGroupName: false,
                    type: "link",
                    icon: "video_camera_front",
                    to: "https://voorbereiden.onderwijsloket.com/ontdek/items/videos/",
                    external: true,
                    image: null,
                    description: null,
                    sort: 1,
                    item: [],
                  },
                  {
                    id: "49e464f0-7710-4261-8163-184f26e3d0e5",
                    name: "Ervaringsverhalen",
                    hideGroupName: false,
                    type: "link",
                    icon: "chat",
                    to: "https://www.onderwijsloket.com/kennisbank/ervaringen-uit-het-onderwijs/",
                    image: null,
                    description: null,
                    sort: 2,
                    item: [],
                  },
                  {
                    id: "f92c20a4-7a5e-4711-88ce-38cba4bd7ff0",
                    name: "Overstappers",
                    hideGroupName: false,
                    type: "link",
                    icon: "group",
                    to: "https://www.onderwijsloket.com/kennisbank/ervaringen-uit-het-onderwijs/",
                    external: true,
                    image: null,
                    description: null,
                    sort: 3,
                    item: [],
                  },
                ],
              },
            ],
          },
          {
            id: "f98c7395-ffce-4f19-9759-576eacd363a7",
            name: "Podcasts section",
            hideGroupName: false,
            type: "section",
            icon: null,
            to: null,
            image: null,
            description: null,
            sort: 2,
            item: [],
            children: [
              {
                id: "beb51f55-8013-475d-948c-3e3fd86d3f34",
                name: "Grote Pauze",
                hideGroupName: false,
                type: "link",
                icon: null,
                to: "https://open.spotify.com/show/179daZbGUFmMCOeVJ2Tv77?si=bff6471550d24c30",
                external: true,
                image: "bebbeed7-5eb9-404b-af55-82fb2a6062d4",
                description:
                  "Onderwijshelden vertellen over wat werken in het onderwijs zo mooi maakt",
                sort: 0,
                item: [],
                children: [],
              },
              {
                id: "5ad0bf90-a388-4d51-becf-335b49c8282f",
                name: "Klaar voor de Stap",
                hideGroupName: false,
                type: "link",
                icon: null,
                to: "https://open.spotify.com/show/3YS6E1qILrKYUweyQNiApf?si=b496e70cb1094d3e",
                external: true,
                image: "0bc146c8-4b60-4cf2-8bd7-13dc78e50489",
                description:
                  "Gouden tips van mensen die midden in hun overstap zitten",
                sort: 1,
                item: [],
                children: [],
              },
            ],
          },
        ],
    },
    {
        id: "6503c15a-945d-41ef-9e07-eb4de547d131",
        name: "Persoonlijk advies",
        hideGroupName: false,
        type: "dropdown",
        icon: null,
        to: "https://www.onderwijsloket.com/plan-een-adviesgesprek/",
        external: true,
        image: null,
        description: null,
        sort: 2,
        item: [],
        children: [
          {
            id: "ef3197b5-40fa-4719-823e-bb9e54c1613a",
            name: "Gratis persoonlijk advies",
            hideGroupName: false,
            type: "link",
            icon: "chat",
            to: "https://www.onderwijsloket.com/plan-een-adviesgesprek/",
            external: true,
            image: null,
            description:
              "Onze adviseurs denken graag mee over jouw mogelijkheden in het onderwijs",
            sort: 0,
            item: [],
            children: [],
          },
          {
            id: "1402e947-bd12-40e3-abf2-c0b27390eeae",
            name: "Link groups",
            hideGroupName: true,
            type: "groups",
            icon: null,
            to: null,
            image: null,
            description: null,
            sort: 1,
            item: [],
            children: [
              {
                id: "086c7748-385b-499e-b7b8-abc48413df44",
                name: "Contact links",
                hideGroupName: true,
                type: "group",
                icon: null,
                to: null,
                image: null,
                description: null,
                sort: 0,
                item: [],
                children: [
                  {
                    id: "4ca2bdf1-60f6-41e3-bee1-6b9dec151b48",
                    name: "Plan een adviesgesprek",
                    hideGroupName: false,
                    type: "link",
                    icon: "calendar_month",
                    to: "https://www.onderwijsloket.com/plan-een-adviesgesprek/",
                    external: true,
                    image: null,
                    description: null,
                    sort: 0,
                    item: [],
                  },
                  {
                    id: "06a74cf0-6b6b-4821-b76f-476efba9e67d",
                    name: "Neem contact met ons op",
                    hideGroupName: false,
                    type: "link",
                    icon: "call",
                    to: "https://www.onderwijsloket.com/contact/",
                    external: true,
                    image: null,
                    description: null,
                    sort: 1,
                    item: [],
                  },
                  {
                    id: "3f40b452-590e-4803-9b33-4fe15d3061ca",
                    name: "Wat kun je van een adviesgesprek verwachten",
                    hideGroupName: false,
                    type: "link",
                    icon: "info",
                    to: "https://www.onderwijsloket.com/plan-een-adviesgesprek/",
                    external: true,
                    image: null,
                    description: null,
                    sort: 2,
                    item: [],
                  },
                ],
              },
            ],
          },
          {
            id: "0c0b308d-49b1-44ba-ab2b-a7813363d545",
            name: "Contact section",
            hideGroupName: true,
            type: "section",
            icon: null,
            to: null,
            image: null,
            description: null,
            sort: 2,
            item: [],
            children: [
              {
                id: "9851a07a-c7b3-4d2b-ac24-377d8ec1a363",
                name: "Onze adviseurs",
                hideGroupName: false,
                type: "link",
                icon: null,
                to: "https://www.onderwijsloket.com/onze-onderwijsexperts/",
                external: true,
                image: "6a7b7be5-78ce-46fc-8787-a731f8fc9d84",
                description: "Maak kennis met onze onderwijsexperts",
                sort: 0,
                item: [],
                children: [],
              },
              {
                id: "81a4f6ff-3719-4612-8138-8372c529141d",
                name: "Regioloketten",
                hideGroupName: false,
                type: "link",
                icon: "storefront",
                iconAsTile: true,
                to: "https://navigator.onderwijsloket.com/regioloketten/",
                external: true,
                image: null,
                description: "Ook in je eigen regio kun je verder geholpen worden",
                sort: 1,
                item: [],
                children: [],
              }
            ]
          }
        ]
    }
]


export const errorSlides = [
    {
      heading: "Vind antwoord op jouw vragen",
      description:
        "In onze kennisbank vind je het antwoord op al jouw vragen over werken in het onderwijs",
      image: {
        public_id: "onderwijsloket/other_resources/defaults/vragen_uuio9a",
      },
      href: 'https://onderwijsloket.com/kennisbank',
    },
    {
      heading: "Klaar voor de stap?",
      description: "Zorg dat je goed voorbereid je overstap naar het onderwijs maakt op ons voorbereidingsplaform",
      image: {
        public_id: "onderwijsloket/other_resources/defaults/voorbereiden_avjd8y",
      },
      href: 'https://voorbereiden.onderwijsloket.com',
    },
    {
      heading: "Jouw route naar het onderwijs",
      description:
        "Vind jouw mogelijke routes naar het onderwijs in onze routetool",
      image: {
        public_id: "onderwijsloket/other_resources/defaults/routes_mttvla",
      },
      href: 'https://onderwijsloket.com/routes/start',
    },
    {
      heading: "Krijg een persoonlijk advies",
      description:
        "De adviseurs van Het Onderwijsloket staan klaar om al jouw vragen te beantwoorden",
      image: {
        public_id: "stichting/site/adviseurs_Anna_02_wrtjct",
      },
      href: 'https://onderwijsloket.com/contact',
    },
    {
      heading: "Vind de juiste contacten",
      description:
        "In de onderwijsnavigator zoek je naar opleidingen, contactpersonen en regioloketten",
      image: {
        public_id: "stichting/site/textile-decoration-scaled-1_ejx5a4",
      },
      to: '/',
    },
]